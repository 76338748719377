<template>
  <div class="soil-carrying-capacity">
    <span class="module-btn" @click="checkModule">模型</span>
    <x-table
      ref="table"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
      @foucesed="foucesed"
    >
    </x-table>

    <select-farm
      v-model="selModal"
      :farmId="farmId"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-farm>

    <Modal v-model="moduleShow" title="土地承载力" :width="1658" footer-hide>
      <div class="model-box">
        <div class="soil-model-box">
          <div class="title-box">
            <p class="title">土壤承载力模型</p>
            <span class="iconfont icon">&#xe6e4;</span>
          </div>
          <div class="content-box">
            <div class="n-yield-box">
              <p class="n-count">
                {{ capacity ? capacity.capacityValue : "--" }}
              </p>
              <p class="unit">头/亩/当季</p>
              <p class="n-text">当前土壤承载力</p>
              <img src="imgs/produceModel/soli_n.png" alt="" />
            </div>
            <div class="formula-and-annotation">
              <div class="formula-box">
                <div class="formula-item">
                  <span class="result">T</span>
                  <span>=</span>
                  <p>
                    <span>Q</span>
                    <span class="line"></span>
                    <span>X</span>
                  </p>
                </div>
              </div>
              <div class="annotation-box">
                <div class="annotation-item">
                  <p>
                    T：土壤承载力（<span>{{
                      capacity ? capacity.capacityValue : "--"
                    }}</span
                    >）
                  </p>
                  <p>
                    Q：区域植物粪肥养分需求量（<span>{{
                      capacity
                        ? capacity.regionalPlantManureNutrientRequirements
                        : "--"
                    }}</span
                    >）
                  </p>
                  <p>
                    X：单位猪当量粪肥养分供给量（<span>{{
                      capacity
                        ? capacity.nutrientSupplyPerPigManureEquivalent
                        : "--"
                    }}</span
                    >）
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="model-item">
          <span class="iconfont icon">&#xe6e4;</span>
          <div class="nutrient">
            区域植物养分需求量 = ∑{每种植物总产量(总面积) x
            单位产量(单位面积)养分需求}
          </div>
          <div class="manure-nutrient">
            <span class="result">区域植物粪肥养分需求量 =</span>
            <p>
              <span>区域植物养分需求量 x 施肥养分供给占比 x 施肥占比</span>
              <span class="line"></span>
              <span>粪肥当季利用率</span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import selectFarm from "@/components/selectFarm/index2.vue";
export default {
  name: "",
  components: {
    selectFarm,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "种植基地名称",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "所属种植户",
            minWidth: 110,
            key: "createUserName",
          },
          {
            title: "当日土壤承载力(头/亩/当季)",
            minWidth: 210,
            key: "capacityValue",
          },
          {
            title: "区域植物粪肥养分需求量(kg)",
            minWidth: 220,
            key: "regionalPlantManureNutrientRequirements",
          },
          {
            title: "品种规模",
            minWidth: 100,
          },
          {
            title: "种植面积",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.area}㎡</span>;
            },
          },
          {
            title: "预估产量",
            minWidth: 100,
          },
          // {
          //   title: "单位猪当量粪肥养分供给量",
          //   minWidth: 210,
          //   key: 'nutrientSupplyPerPigManureEquivalent'
          // },
          {
            title: "记录时间",
            minWidth: 180,
            key: "createTime",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 13,
        total: 0,
      },
      search_data: {},
      farmScene: [],
      capacity: null,
      farmId: "",
      farmName: "",
      selModal: false,
      options: {
        siteType: "1",
      },
      moduleShow: false
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 200,
          searchBtnHide: true,
          filterBox: [
            // {
            //   conditionName: "种植基地",
            //   component: "select",
            //   field: "farmId",
            //   data: this.farmScene,
            //   defaultValue: "",
            //   parameterField: "id",
            //   showField: "name",
            // },
            {
              conditionName: "种植基地",
              component: "input",
              field: "farmName",
              defaultValue: this.farmName,
              isFocus: true,
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    checkModule() {
      this.moduleShow = true
    },
    foucesed() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.farmId = farm.id;
      this.farmName = farm.name;
      this.search_data.farmId = farm.id + "";
      this.$nextTick(() => {
        this.$refs.table.setDefaults();
      });
      this.page.pageNo = 1;
      this.getList();
      this.getCapacity();
      this.selModal = false;
    },
    search(data) {
      this.page.pageNo = 1;
      this.getList();
      this.getCapacity();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.SOIL_CARRYING_CAPACITY_MODEL.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total || 0;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    getCapacity() {
      this.$post(this.$api.SOIL_CARRYING_CAPACITY_MODEL.CARRYING_CAPACITY, {
        ...this.search_data,
      }).then((res) => {
        this.capacity = res[0] || null;
      });
    },
    getBreedArea() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: "2",
      }).then((res) => {
        this.farmScene = res.list;
        // this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList");
      });
    },
  },
  mounted() {
    this.getList();
    // this.getBreedArea();
    this.getCapacity();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>